import get from 'lodash.get';
import ApplicationInaccessible from 'modules/consumer-onboarding/v2/ApplicationInaccessible';
import { INACCESSIBLE_MESSAGE } from 'modules/consumer-onboarding/v2/hooks/useApplicationAccessibilityState';
import { getIsAntifraudAml } from 'modules/identity/components/utils';
import { GridContainer } from 'modules/request-permission-flow/containers/Layout/styles';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import Overlay from 'modules/shared/components/top/Overlay';
import Footer from 'modules/shared/components/v2/Footer';
import Loader from 'modules/shared/components/widgets/static/Loader';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import getApplicableAntiFraudRulesForAuthorisationFlow from 'utils/anti-fraud/getApplicableAntiFraudRulesForAuthorisationFlow';
import extractAttachmentUrl from 'utils/extractAttachmentUrl';
import isPresent from 'utils/isPresent';

import { authorisationLogin, loadAuthorisation } from '../actions';
import { componentFactory } from '../index';
import styles from './css/AuthorisationOnBoarding.css';
import { FEATURE_FLAGS } from 'conf';
import { TERMS_AND_CONDITIONS } from 'constants';
import { customTermsUrl } from 'utils/extractAttachmentUrl';

const tncs = {
  guarantor: {
    AU: TERMS_AND_CONDITIONS.AU.guarantor,
    NZ: TERMS_AND_CONDITIONS.NZ.guarantor,
  },
  paperless: {
    AU: TERMS_AND_CONDITIONS.AU.paperless,
    NZ: TERMS_AND_CONDITIONS.NZ.paperless,
  },
  supplier: {
    AU: TERMS_AND_CONDITIONS.AU.supplier,
    NZ: TERMS_AND_CONDITIONS.NZ.supplier,
  },
};

class AuthorisationOnBoarding extends Component {
  state = {
    currentSectionIndex: 0,
    showLoader: true,
  };

  componentDidMount() {
    const {
      dispatch,
      location: {
        query: { t },
      },
      params,
    } = this.props;
    if (params.authorisation_id && t) {
      dispatch(
        authorisationLogin(params.authorisation_id, t, () => {
          dispatch(loadAuthorisation(params.authorisation_id));
        })
      );
    }
  }

  redirect(sectionNameIndex) {
    const { sections, params, location } = this.props;
    browserHistory.push({
      pathname: `/authorisation/${params.authorisation_id}/${sections[
        sectionNameIndex
      ].replace('_', '-')}`,
      query: location.query,
    });
  }

  toNextSection() {
    const currentSectionIndex = this.state.currentSectionIndex + 1;
    this.setState({ currentSectionIndex });
    this.redirect(currentSectionIndex);
  }

  toPreviousSection() {
    const currentSectionIndex = this.state.currentSectionIndex - 1;
    this.setState({ currentSectionIndex });
    this.redirect(currentSectionIndex);
  }

  toLastSection() {
    const { sections } = this.props;
    const currentSectionIndex = sections.length - 1;
    this.setState({ currentSectionIndex });
    this.redirect(currentSectionIndex);
  }

  sectionProps() {
    const {
      consumerName,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      hasSignatory,
      requireProofOfAddress,
      params,
      supplierLegalName,
      isTrustApplication,
      trustApplicantName,
      corporateTrusteeName,
    } = this.props;

    return {
      consumerName,
      hasCardholder,
      hasGuarantor,
      hasPaperless,
      hasSignatory,
      params,
      requireProofOfAddress,
      supplierLegalName,
      isTrustApplication,
      trustApplicantName,
      corporateTrusteeName,
      toLastSection: this.toLastSection.bind(this),
      toNextSection: this.toNextSection.bind(this),
      toPreviousSection: this.toPreviousSection.bind(this),
    };
  }

  loadingComplete() {
    this.setState({ showLoader: false });
  }

  getTermsAndConditionsUrl() {
    const { customTermsConfigs, region, termList } = this.props;

    const termsList = [];

    termList.forEach((type) => {
      if (customTermsConfigs[type].uses_custom_terms) {
        termsList.push({
          type,
          url: customTermsUrl(customTermsConfigs[type].custom_terms).url,
        });
      } else {
        termsList.push({
          type,
          url: tncs[type][region],
        });
      }
    });

    return termsList;
  }

  render() {
    const { authorisation, sections, loading, supplierLegalName, logoUrl } =
      this.props;
    const { showLoader } = this.state;
    const childComponent = componentFactory(
      sections[this.state.currentSectionIndex],
      this.sectionProps()
    );

    const applicationInaccessibleReason = get(
      authorisation,
      'application.attributes.inaccessible_reason'
    );

    if (showLoader) {
      return (
        <Loader
          message="Please wait while we load your progress."
          loading={loading || !authorisation}
          handleComplete={() => this.loadingComplete()}
        />
      );
    }

    if (isPresent(applicationInaccessibleReason)) {
      return (
        <GridContainer>
          <Overlay supplierLogoUrl={logoUrl} withButtons={false} />
          <ApplicationInaccessible
            header="Application inaccessible"
            message={`${INACCESSIBLE_MESSAGE[applicationInaccessibleReason]} Please directly get in touch with ${supplierLegalName} to discuss any questions you may have.`}
          />
          <Footer />
        </GridContainer>
      );
    }

    let overlay = FEATURE_FLAGS.FEATURE_FLAGS_AUTH_TNCS ? (
      <Overlay
        supplierLogoUrl={logoUrl}
        withButtons
        termsList={this.getTermsAndConditionsUrl()}
      />
    ) : (
      <ApplicationOverlay
        trading_name={supplierLegalName}
        logo_url={logoUrl}
        label={'Authorisation'}
        color={'xlight'}
      />
    );

    if (sections[this.state.currentSectionIndex] === 'complete') {
      overlay = null;
    }

    return (
      <div>
        <div className={styles.controls}>{overlay}</div>
        <div className={styles.page}>
          <div className={styles.container}>
            <div className={styles.application_content}>{childComponent}</div>
          </div>
        </div>
      </div>
    );
  }
}

function generateTrustApplicantName(application, people) {
  if (!FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS) return null;

  let trustees = '';
  let trusteesArray = people
    .filter(
      (person) =>
        person &&
        person.attributes &&
        person.attributes.legal_type === 'trustee'
    )
    .map((person) => person.attributes.name);

  const corporateTrustees = application.corporate_trustees || [];
  if (corporateTrustees.length > 0) {
    corporateTrustees.forEach((corporateTrust) =>
      trusteesArray.unshift(corporateTrust.entity_name)
    );
  }

  if (trusteesArray.length > 0) {
    trustees = trusteesArray.join(', ');
  }

  if (trustees.length > 0) {
    const companyName = (
      application.company_name || application.consumer_name
    ).replace(/the trustee for/i, '');
    trustees += ` ATF ${companyName}`;
  }
  return trustees;
}

export default connect((state) => {
  const sections = ['abstract', 'identity_details', 'terms', 'complete'];

  const antiFraudCategoryRules =
    getApplicableAntiFraudRulesForAuthorisationFlow(state.authorisation);

  if (!antiFraudCategoryRules.requiresIdentificationSection) {
    const indexOfIdentity = sections.indexOf('identity_details');
    sections.splice(indexOfIdentity, 1);
  }

  const logo = extractAttachmentUrl(
    get(state, 'authorisation.data.supplier.attributes.logo', {}),
    'logo'
  );
  let requireProofOfAddress =
    state.authorisation.data &&
    state.authorisation.data.authorisation.attributes.require_proof_of_address;

  const { hasCardholder, hasGuarantor, hasPaperless, hasSignatory } =
    state.authorisation || {};

  const termList = ['supplier'];
  if (hasGuarantor) {
    termList.push('guarantor');
  }
  if (hasPaperless) {
    termList.push('paperless');
  }

  const applicationAttributes = get(
    state,
    'authorisation.data.application.attributes',
    {}
  );
  const customTermsConfigs = {
    guarantor: {
      custom_terms: applicationAttributes.custom_guarantor_terms,
      uses_custom_terms: applicationAttributes.uses_custom_guarantor_terms,
    },
    paperless: {
      custom_terms: applicationAttributes.custom_paperless_terms,
      uses_custom_terms: applicationAttributes.uses_custom_paperless_terms,
    },
    supplier: {
      custom_terms: applicationAttributes.custom_terms,
      uses_custom_terms: applicationAttributes.uses_custom_supplier_terms,
    },
  };

  if (!requireProofOfAddress) {
    const antifraudAttributes = get(
      state,
      'authorisation.antiFraud.attributes',
      {}
    );

    const isAntiFraudCheckCardholder = hasCardholder
      ? getIsAntifraudAml({
          antifraudAttributes,
          person: 'cardholder',
        })
      : false;
    const isAntiFraudCheckGuarantor = hasGuarantor
      ? getIsAntifraudAml({
          antifraudAttributes,
          person: 'guarantor',
        })
      : false;
    const isAntiFraudCheckSignatory = hasSignatory
      ? getIsAntifraudAml({
          antifraudAttributes,
          person: 'signatory',
        })
      : false;

    requireProofOfAddress =
      isAntiFraudCheckCardholder ||
      isAntiFraudCheckGuarantor ||
      isAntiFraudCheckSignatory;
  }

  const isTrustApplication = applicationAttributes.legal_type === 'trust';

  const trustApplicantName = isTrustApplication
    ? generateTrustApplicantName(
        applicationAttributes,
        get(state, 'authorisation.data.people', [])
      )
    : null;

  let corporateTrusteeName;
  if (FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS && isTrustApplication) {
    const associatedEntityId = get(
      state,
      'authorisation.data.authorisation.attributes.associated_entity_id',
      null
    );

    if (associatedEntityId) {
      const corporateTrustees = get(
        applicationAttributes,
        'corporate_trustees',
        []
      );

      const currentCorporateTrustee = corporateTrustees.find(
        (corporateTrustee) => corporateTrustee.id === associatedEntityId
      );
      corporateTrusteeName = currentCorporateTrustee
        ? currentCorporateTrustee.entity_name
        : null;
    }
  }

  return {
    authorisation: state.authorisation.data,
    consumerName: state.authorisation.consumerName,
    hasCardholder,
    hasGuarantor,
    hasPaperless,
    hasSignatory,
    loading: state.authorisation.loading,
    logoUrl: logo.url,
    requireProofOfAddress,
    sections,
    supplierLegalName: state.authorisation.supplierLegalName,
    customTermsConfigs,
    region: applicationAttributes.region,
    termList,
    isTrustApplication,
    trustApplicantName,
    corporateTrusteeName,
  };
})(AuthorisationOnBoarding);
